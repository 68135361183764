import React, { useState, useEffect } from "react";

async function downloadImage(data) {
  const fileName = data;
  console.log("이미지 파일명:", fileName);

  try {
    const URLresponse = await fetch(
      `https://photodaehak.shop/api/downloads/${fileName}`
    );
    if (!URLresponse.ok) {
      throw new Error(`Server returned ${URLresponse.status}`);
      return false;
    }
    const imageURL = await URLresponse.blob();
    console.log("이미지 URL:", imageURL);
    saveAs(imageURL, fileName);
    return true;
  } catch (error) {
    console.error("파일 다운로드 중 에러 발생:", error);
    return false;
  }
}

function DataFetcher({ auth }) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setLoading(true);
      fetch(`https://photodaehak.shop/api/physiognomy-match?auth=${auth}`, {
        method: "GET",
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Server returned ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          if (data) {
            // 데이터가 null이 아니면 상태 업데이트
            setData(data);
            setLoading(false);
            clearInterval(intervalId); // 데이터를 받아왔으므로 인터벌 중지
          }
        })
        .catch((error) => {
          console.error("Fetching error:", error);
          setError(error);
          setLoading(false);
        });
    }, 5000); // 5초마다 반복

    return () => clearInterval(intervalId); // 컴포넌트가 언마운트될 때 인터벌 중지
  }, [auth]); // url이나 auth가 변경되면 다시 실행

  if (loading) console.log("Loading data...");
  if (error) console.log("Error fetching data:", error);
  if (!data) console.log("No data yet");

  return { data, loading };
}

export { DataFetcher, downloadImage };
