import { clear } from "@testing-library/user-event/dist/clear";
import React from "react";
import { useState, useEffect } from "react";

function SubmitStatusShowBox(props) {
  console.log(props);
  const texts = [
    "제출 중입니다.",
    "제출 중입니다..",
    "제출 중입니다...",
    "제출 중입니다....",
    "제출 중입니다.....",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    let timer;
    if (props.submitted) {
      timer = setInterval(() => {
        setCurrentIndex((currentIndex) => {
          if (currentIndex === texts.length - 1) {
            return 0;
          } else {
            return currentIndex + 1;
          }
        });
      }, 500);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  });

  return (
    <div>
      <div
        style={{
          visibility: props.submitted && !props.turnoff ? "visible" : "hidden",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: 210,
            height: 20,
            left: 75,
            top: 438,
            textAlign: "center",
            color: "rgba(0, 0, 0, 0.70)",
            fontSize: 16,
            fontFamily: "Pretendard",
            fontWeight: "600",
            wordWrap: "break-word",
          }}
        >
          {texts[currentIndex]}
        </div>
      </div>
      <div
        style={{
          visibility: props.failed && !props.turnoff ? "visible" : "hidden",
        }}
      >
        <div
          style={{
            width: 60,
            height: 60,
            left: 150,
            top: 341,
            position: "absolute",
          }}
        >
          <svg
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_151_2)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 30C0 22.0435 3.16071 14.4129 8.7868 8.7868C14.4129 3.16071 22.0435 0 30 0C37.9565 0 45.5871 3.16071 51.2132 8.7868C56.8393 14.4129 60 22.0435 60 30C60 37.9565 56.8393 45.5871 51.2132 51.2132C45.5871 56.8393 37.9565 60 30 60C22.0435 60 14.4129 56.8393 8.7868 51.2132C3.16071 45.5871 0 37.9565 0 30ZM40.588 43.416L30 32.828L19.416 43.416L16.584 40.588L27.172 30L16.584 19.416L19.416 16.584L30 27.172L40.584 16.584L43.416 19.416L32.828 30L43.416 40.584L40.588 43.416Z"
                fill="#E64614"
              />
            </g>
            <defs>
              <clipPath id="clip0_151_2">
                <rect width="60" height="60" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div
          style={{
            position: "absolute",
            width: 300,
            height: 20,
            left: 30,
            top: 438,
            textAlign: "center",
            color: "rgba(0, 0, 0, 0.70)",
            fontSize: 13,
            fontFamily: "Pretendard",
            fontWeight: "600",
            wordWrap: "break-word",
            whiteSpace: "pre-wrap",
          }}
        >
          {
            "제출에 실패했습니다. 잠시 후 다시 시도해주세요.\n문제가 지속될 경우 대표번호 010-7475-0682번으로 연락바랍니다."
          }
        </div>
      </div>
      <div
        style={{
          visibility: props.completed && !props.turnoff ? "visible" : "hidden",
        }}
      >
        <div
          style={{
            width: 60,
            height: 60,
            left: 150,
            top: 341,
            position: "absolute",
          }}
        >
          <svg
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_35_531)">
              <path
                d="M30 0C32.7539 0 35.4102 0.351562 37.9688 1.05469C40.5273 1.75781 42.9102 2.77344 45.1172 4.10156C47.3242 5.42969 49.3457 6.99219 51.1816 8.78906C53.0176 10.5859 54.5898 12.6074 55.8984 14.8535C57.207 17.0996 58.2129 19.4922 58.916 22.0312C59.6191 24.5703 59.9805 27.2266 60 30C60 32.7539 59.6484 35.4102 58.9453 37.9688C58.2422 40.5273 57.2266 42.9102 55.8984 45.1172C54.5703 47.3242 53.0078 49.3457 51.2109 51.1816C49.4141 53.0176 47.3926 54.5898 45.1465 55.8984C42.9004 57.207 40.5078 58.2129 37.9688 58.916C35.4297 59.6191 32.7734 59.9805 30 60C27.2461 60 24.5898 59.6484 22.0312 58.9453C19.4727 58.2422 17.0898 57.2266 14.8828 55.8984C12.6758 54.5703 10.6543 53.0078 8.81836 51.2109C6.98242 49.4141 5.41016 47.3926 4.10156 45.1465C2.79297 42.9004 1.78711 40.5078 1.08398 37.9688C0.380859 35.4297 0.0195312 32.7734 0 30C0 27.2461 0.351562 24.5898 1.05469 22.0312C1.75781 19.4727 2.77344 17.0898 4.10156 14.8828C5.42969 12.6758 6.99219 10.6543 8.78906 8.81836C10.5859 6.98242 12.6074 5.41016 14.8535 4.10156C17.0996 2.79297 19.4922 1.78711 22.0312 1.08398C24.5703 0.380859 27.2266 0.0195312 30 0ZM47.666 20.0684L43.6816 16.084L24.375 35.3906L16.3184 27.334L12.334 31.3184L24.375 43.3594L47.666 20.0684Z"
                fill="#009D35"
              />
            </g>
            <defs>
              <clipPath id="clip0_35_531">
                <rect width="60" height="60" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div
          style={{
            position: "absolute",
            width: 210,
            height: 20,
            left: 75,
            top: 438,
            textAlign: "center",
            color: "rgba(0, 0, 0, 0.70)",
            fontSize: 13,
            fontFamily: "Pretendard",
            fontWeight: "600",
            wordWrap: "break-word",
          }}
        >
          {props.extraMessage
            ? "제출이 성공적으로 완료되었습니다." + props.extraMessage
            : "제출이 성공적으로 완료되었습니다."}
        </div>
      </div>
    </div>
  );
}

export default SubmitStatusShowBox;
