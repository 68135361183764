import React from "react";

function ContentsHeaderBox(props) {
  return (
    <div
      style={{
        width: "100%",
        height: "130vh",
        position: "absolute",
        top: 0,
        left: 0,
        background: props.color,
        zIndex: -1,
      }}
    >
      <div style={{ left: 30, top: 36, position: "absolute" }}>
        <span
          style={{
            color: "black",
            fontSize: 16,
            fontFamily: "THEAlien",
            fontWeight: "400",
            wordWrap: "break-word",
          }}
        >
          {props.subheading}
          <br />
        </span>
        <span
          style={{
            color: "black",
            fontSize: 24,
            fontFamily: "THEAlien",
            fontWeight: "400",
            wordWrap: "break-word",
          }}
        >
          {props.mainheading}
        </span>
      </div>
      <div
        style={{
          left: 30,
          top: 101,
          position: "absolute",
          color: "#595959",
          fontSize: 8,
          fontFamily: "Pretendard",
          fontWeight: "300",
          wordWrap: "break-word",
        }}
      >
        {props.instruction}
      </div>
    </div>
  );
}

export default ContentsHeaderBox;
