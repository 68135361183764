import { useState } from "react";

const useEnrollmentForm = () => {
  const [name, setName] = useState("");
  const [studentId, setStudentId] = useState("");
  const [admissionYear, setAdmissionYear] = useState("");
  const [auth, setAuth] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [failed, setFailed] = useState(false);
  const [useRightForm, setUseRightForm] = useState(true);

  const validateForm = () => {
    // 이름 유효성 검사
    const isNameInvalid =
      /([ㄱ-ㅎ|ㅏ-ㅣ|가-힣]|\s){6,}/.test(name) ||
      /([A-Za-z]|\s){11,}/.test(name);
    // 학번 유효성 검사
    const isStudentIdValid = /^\d{8}$/.test(studentId);

    return !isNameInvalid && isStudentIdValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = { name, auth, studentId, admissionYear };
    console.log("제출 데이터:", formData);

    // 유효성 검사 실행
    if (!validateForm()) {
      console.error("입력 형식이 잘못되었습니다.");
      setUseRightForm(false); // 유효하지 않은 형식임을 나타냄
      return; // 제출 과정 중단
    }

    try {
      setSubmitted(true);
      const response = await fetch(
        "https://photodaehak.shop/api/unist-enrollment",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        console.log("제출 성공");
        setName("");
        setStudentId("");
        setAdmissionYear("");
        setSubmitted(false);
        setCompleted(true);
      } else {
        setSubmitted(false);
        setFailed(true);
        console.error("제출 실패");
      }
    } catch (error) {
      setSubmitted(false);
      setFailed(true);
      console.error("API 요청 중 에러 발생:", error);
    }
  };

  return {
    name,
    setName,
    auth,
    setAuth,
    studentId,
    setStudentId,
    admissionYear,
    setAdmissionYear,
    submitted,
    setSubmitted,
    completed,
    setCompleted,
    failed,
    setFailed,
    useRightForm,
    setUseRightForm,
    handleSubmit,
  };
};

export default useEnrollmentForm;
