import React, { useEffect, useState } from "react";
import ContentsHeaderBox from "../../../../components/SubmissionContentsHeader";
import useEnrollmentForm from "./model";
import { useSearchParams } from "react-router-dom";
import SubmitStatusShowBox from "../../../../components/SubmitStautsShower";
import YuniImageLogo from "../../../../assets/images/logo-mascot-symbol.png";
import YuniImage from "../../../../assets/images/logo-mascot-only.png";

function UnistEnrollmentMobilePageView() {
  let {
    name,
    setName,
    auth,
    setAuth,
    studentId,
    setStudentId,
    admissionYear,
    setAdmissionYear,
    submitted,
    setSubmitted,
    completed,
    setCompleted,
    failed,
    setFailed,
    useRightForm,
    setUseRightForm,
    handleSubmit,
  } = useEnrollmentForm();

  let [searchParams] = useSearchParams();
  const authcode = searchParams.get("auth");

  useEffect(() => {
    setAuth(authcode);
  }, []);

  return (
    <div style={{ visibility: auth ? "visible" : "hidden" }}>
      <div
        style={{ visibility: useRightForm ? "hidden" : "visible" }}
        onClick={() => setUseRightForm(true)}
      >
        <div style={{ width: 360, height: 800, position: "absolute" }}>
          <div
            style={{
              width: 360,
              height: 1000,
              left: 0,
              top: 0,
              position: "absolute",
              background: "rgba(255, 255, 255, 0.10)",
              backdropFilter: "blur(12px)",
              zIndex: 1,
            }}
          />
          <div
            style={{
              width: 292,
              height: 137,
              left: 34,
              top: 294,
              position: "absolute",
              background: "rgba(200, 200, 79, 0.75)",
              boxShadow: "0px 0px 4px 2px rgba(0, 0, 0, 0.25)",
              borderRadius: 8,
              zIndex: 2,
            }}
          />
          <div
            style={{
              width: 32.82,
              height: 30,
              left: 48,
              top: 313,
              position: "absolute",
              zIndex: 2,
            }}
          >
            <div
              style={{
                width: 32.7,
                height: 30,
                left: 0.12,
                top: 0,
                position: "absolute",
                zIndex: 2,
              }}
            >
              <svg
                width="33"
                height="30"
                viewBox="0 0 33 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_198_31)">
                  <path
                    d="M20.4403 2.76937C19.4044 0.984375 17.9944 0 16.47 0C14.9456 0 13.5347 0.984375 12.4987 2.76937L0.996562 22.5816C-0.0534379 24.4069 -0.165938 26.3025 0.687187 27.7809C1.49906 29.1872 3.09187 29.9962 5.05969 30H27.8803C29.8509 29.9962 31.4466 29.19 32.2556 27.7866C33.1041 26.3175 32.9897 24.4294 31.9416 22.6069L20.4403 2.76937ZM31.4447 27.3178C30.8062 28.4241 29.5069 29.0597 27.8784 29.0625H5.06062C3.43781 29.0597 2.14031 28.4212 1.49906 27.3122C0.8175 26.1309 0.930937 24.5775 1.80844 23.0503L13.3097 3.24C14.1712 1.755 15.2934 0.9375 16.47 0.9375C17.6466 0.9375 18.7687 1.755 19.6294 3.24L31.1306 23.0747C32.0072 24.5991 32.1216 26.1459 31.4447 27.3178Z"
                    fill="#F24E1E"
                  />
                  <path
                    d="M16.47 18.75C16.5943 18.75 16.7135 18.7006 16.8015 18.6127C16.8894 18.5248 16.9387 18.4056 16.9387 18.2812V8.90625C16.9387 8.78193 16.8894 8.6627 16.8015 8.57479C16.7135 8.48689 16.5943 8.4375 16.47 8.4375C16.3457 8.4375 16.2265 8.48689 16.1385 8.57479C16.0506 8.6627 16.0012 8.78193 16.0012 8.90625V18.2812C16.0012 18.4056 16.0506 18.5248 16.1385 18.6127C16.2265 18.7006 16.3457 18.75 16.47 18.75ZM16.41 21.0938C15.3759 21.0938 14.535 21.9347 14.535 22.9688C14.535 24.0028 15.3759 24.8438 16.41 24.8438C17.4441 24.8438 18.285 24.0028 18.285 22.9688C18.285 21.9347 17.4441 21.0938 16.41 21.0938ZM16.41 23.9062C16.1686 23.8955 15.9407 23.792 15.7737 23.6173C15.6067 23.4427 15.5135 23.2104 15.5135 22.9688C15.5135 22.7271 15.6067 22.4948 15.7737 22.3202C15.9407 22.1455 16.1686 22.042 16.41 22.0312C16.6514 22.042 16.8793 22.1455 17.0463 22.3202C17.2133 22.4948 17.3065 22.7271 17.3065 22.9688C17.3065 23.2104 17.2133 23.4427 17.0463 23.6173C16.8793 23.792 16.6514 23.8955 16.41 23.9062Z"
                    fill="#F24E1E"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_198_31">
                    <rect width="32.82" height="30" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div
            style={{
              left: 48,
              top: 362,
              position: "absolute",
              color: "black",
              fontSize: 16,
              fontFamily: "Pretendard",
              fontWeight: "600",
              wordWrap: "break-word",
              zIndex: 2,
            }}
          >
            성명 또는 학번이 조건에 맞지 않습니다.
            <br />
            올바른 조건의 정보를 입력해주세요.
          </div>
        </div>
      </div>
      <ContentsHeaderBox
        subheading={"윤이가 말아주는 ★"}
        mainheading={"유니스트 재학증명서"}
        instruction={"아래 폼에 내용을 기입해주세요."}
        color={"rgba(255, 244, 79, 0.50)"}
      />
      <form
        onSubmit={(e) => handleSubmit(e)}
        style={{
          position: "absolute",
          visibility:
            failed === false &&
            submitted === false &&
            completed === false &&
            auth != null
              ? "visible"
              : "hidden",
        }}
      >
        <div
          style={{
            width: 352,
            height: 75,
            left: 30,
            top: 161,
            position: "absolute",
          }}
        >
          <div
            style={{
              width: 323,
              height: 0,
              left: 0,
              top: 31.5,
              position: "absolute",
              boxShadow: "1px 1px 1px ",
              border: "3px rgba(12.75, 12.75, 12.75, 0.30) solid",
              filter: "blur(1px)",
            }}
          ></div>
          <div
            style={{
              width: 60,
              height: 20,
              left: 0,
              top: 0,
              position: "absolute",
              color: "rgba(0, 0, 0, 0.70)",
              fontSize: 20,
              fontFamily: "Pretendard",
              fontWeight: "800",
              wordWrap: "break-word",
            }}
          >
            성명
          </div>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="한국어 5자, 영어 10자 이내의 이름"
            style={{
              width: 273,
              height: 27,
              left: 0,
              top: 48,
              fontFamily: "Pretendard",
              fontWeight: "400",
              position: "absolute",
              background: "white",
              boxShadow:
                "0px 0px 4px 2px rgba(80.75, 80.75, 80.75, 0.15) inset",
              borderRadius: 4,
              border: "1px rgba(127.50, 127.50, 127.50, 0.70) solid",
            }}
          />
        </div>

        <div
          style={{
            width: 352,
            height: 75,
            left: 30,
            top: 286,
            position: "absolute",
          }}
        >
          <div
            style={{
              width: 323,
              height: 0,
              left: 0,
              top: 31.5,
              position: "absolute",
              boxShadow: "1px 1px 1px ",
              border: "3px rgba(12.75, 12.75, 12.75, 0.30) solid",
              filter: "blur(1px)",
            }}
          ></div>
          <div
            style={{
              width: 60,
              height: 20,
              left: 0,
              top: 0,
              position: "absolute",
              color: "rgba(0, 0, 0, 0.70)",
              fontSize: 20,
              fontFamily: "Pretendard",
              fontWeight: "800",
              wordWrap: "break-word",
            }}
          >
            학번
          </div>
          <input
            type="number"
            value={studentId}
            maxLength={8}
            onChange={(e) => setStudentId(e.target.value)}
            placeholder="ex) 20241000"
            style={{
              width: 273,
              height: 27,
              left: 0,
              top: 48,
              position: "absolute",
              background: "white",
              fontFamily: "Pretendard",
              fontWeight: "400",
              boxShadow:
                "0px 0px 4px 2px rgba(80.75, 80.75, 80.75, 0.15) inset",
              borderRadius: 4,
              border: "1px rgba(127.50, 127.50, 127.50, 0.70) solid",
            }}
          />
        </div>
        <div
          style={{
            width: 352,
            height: 75,
            left: 30,
            top: 411,
            position: "absolute",
          }}
        >
          <div
            style={{
              width: 323,
              height: 0,
              left: 0,
              top: 31.5,
              position: "absolute",
              boxShadow: "1px 1px 1px ",
              border: "3px rgba(12.75, 12.75, 12.75, 0.30) solid",
              filter: "blur(1px)",
            }}
          ></div>
          <div
            style={{
              width: 76,
              height: 20,
              left: 0,
              top: 0,
              position: "absolute",
              color: "rgba(0, 0, 0, 0.70)",
              fontSize: 20,
              fontFamily: "Pretendard",
              fontWeight: "800",
              wordWrap: "break-word",
            }}
          >
            입학일자
          </div>
          <input
            type="date"
            value={admissionYear}
            onChange={(e) => setAdmissionYear(e.target.value)}
            style={{
              width: 273,
              height: 27,
              left: 0,
              top: 48,
              fontFamily: "Pretendard",
              fontWeight: "400",
              position: "absolute",
              background: "white",
              boxShadow:
                "0px 0px 4px 2px rgba(80.75, 80.75, 80.75, 0.15) inset",
              borderRadius: 4,
              border: "1px rgba(127.50, 127.50, 127.50, 0.70) solid",
            }}
          />
        </div>
        <button
          type="submit"
          style={{
            width: 276,
            height: 48,
            left: 42,
            top: 712,
            background: "#070035",
            boxShadow:
              "0px 0px 15.899999618530273px 6px rgba(255, 255, 255, 0.40) inset",
            borderRadius: 2,
            border: "2px rgba(255, 255, 255, 0.50) solid",
            position: "absolute",
            textAlign: "center",
            color: "rgba(255, 255, 255, 0.70)",
            fontSize: 20,
            fontFamily: "Pretendard",
            fontWeight: "600",
            wordWrap: "break-word",
          }}
        >
          제출하기
        </button>
      </form>
      <SubmitStatusShowBox
        submitted={submitted}
        completed={completed}
        failed={failed}
      />
    </div>
  );
}

export default UnistEnrollmentMobilePageView;
