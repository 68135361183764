import React from "react";
import "./assets/fonts/fonts.css";
import HomePageView from "./pages/home/HomePage";
import UnistEnrollmentPageView from "./pages/contents/unist-enrollment/pc/view";
import UnistEnrollmentMobilePageView from "./pages/contents/unist-enrollment/mobile/view";
import PhysiognomyMatchView from "./pages/contents/physiognomy-match/pc/view";
import PhysiognomyMatchMobileView from "./pages/contents/physiognomy-match/mobile/view";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DescriptionPayView from "./pages/paymentPage/paymentPage";
import "./App.css";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePageView />} />
        <Route path="/description" element={<DescriptionPayView />} />
        <Route
          path="/contents/unist-enrollment"
          element={<UnistEnrollmentPageView />}
        />
        <Route
          path="/mobile/unist-enrollment"
          element={<UnistEnrollmentMobilePageView />}
        />
        <Route
          path="/contents/physiognomy-match"
          element={<PhysiognomyMatchView />}
        />
        <Route
          path="/mobile/physiognomy-match"
          element={<PhysiognomyMatchMobileView />}
        />
      </Routes>
    </Router>
  );
}

export default App;
