import { useState } from "react";

async function showImage({ url }) {
  console.log("이미지 파일명:", url);

  try {
    const URLresponse = await fetch(
      `https://photodaehak.shop/api/downloads/${url}`
    );
    if (!URLresponse.ok) {
      throw new Error(`Server returned ${URLresponse.status}`);
      return false;
    }
    const imageBlob = await URLresponse.blob();
    const imageURL = URL.createObjectURL(imageBlob);
    console.log("이미지 URL:", imageURL);
    return imageURL;
  } catch (error) {
    console.error("파일 다운로드 중 에러 발생:", error);
    return false;
  }
}

const confirmImage = () => {
  const [confirmRequested, setConfirmRequested] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [confirmFailed, setConfirmFailed] = useState(false);
  const [auth2, setAuth2] = useState("");

  const handleConfirm = async (event) => {
    event.preventDefault();
    const formData = { auth: auth2 };
    console.log("확인 데이터:", formData);
    setConfirmRequested(true);

    try {
      const response = await fetch(
        "https://photodaehak.shop/api/physiognomy-match/submission",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        }
      );
      if (response.ok) {
        setConfirmed(true);
        setConfirmRequested(false);
      } else {
        setConfirmFailed(true);
        setConfirmRequested(false);
      }
    } catch (error) {
      console.error("API 요청 중 에러 발생:", error);
      setConfirmFailed(true);
      setConfirmRequested(false);
    }
  };

  return {
    confirmRequested,
    setConfirmRequested,
    confirmed,
    setConfirmed,
    confirmFailed,
    setConfirmFailed,
    auth2,
    setAuth2,
    handleConfirm,
  };
};

const usePhysForm = () => {
  const [file, setFile] = useState("");
  const [auth, setAuth] = useState("");
  const [faceNum, setFaceNum] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [failed, setFailed] = useState(false);
  const [imageURL, setImageURL] = useState("");
  const [getImage, setGetImage] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      return;
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = async () => {
        setFile(reader.result);
        const formData = { file: reader.result, auth };
        console.log("제출 데이터:", formData);

        try {
          setSubmitted(true);
          const response = await fetch(
            "https://photodaehak.shop/api/physiognomy-match/facialcheck",
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(formData),
            }
          );

          if (response.ok) {
            console.log("제출 성공");
            setCompleted(true);
            const receiveData = await response.json();
            console.log("받은 데이터:", receiveData.url);
            const url = receiveData.url;
            const faceNum = receiveData.face_num;
            console.log("얼굴 감지 개수:", receiveData.face_num);
            setFaceNum(faceNum);
            setImageURL(await showImage({ url }));
            setGetImage(true);
          } else {
            setSubmitted(false);
            setFailed(true);
            console.error("제출 실패");
          }
        } catch (error) {
          setSubmitted(false);
          setFailed(true);
          console.error("API 요청 중 에러 발생:", error);
        }
      };
    }
  };

  return {
    file,
    setFile,
    auth,
    setAuth,
    faceNum,
    setFaceNum,
    imageURL,
    setImageURL,
    submitted,
    setSubmitted,
    completed,
    setCompleted,
    failed,
    setFailed,
    getImage,
    setGetImage,
    handleSubmit,
  };
};

export { usePhysForm, showImage, confirmImage };
