import React from "react";

function KioskContentsHeader(props) {
  return (
    <div
      style={{
        width: 1080,
        height: 1920,
        position: "absolute",
        top: 0,
        left: 0,
        background: props.color,
      }}
    >
      <div
        style={{
          width: 525,
          height: 211,
          left: 151,
          top: 200,
          position: "absolute",
        }}
      >
        <div style={{ left: 0, top: 0, position: "absolute" }}>
          <span
            style={{
              color: "black",
              fontSize: 40,
              fontFamily: "THEAlien",
              fontWeight: "500",
              wordWrap: "break-word",
              visibility: !props.data ? "visible" : "hidden",
            }}
          >
            {props.subheading}
            <br />
          </span>
          <span
            style={{
              color: "black",
              fontSize: 64,
              fontFamily: "THEAlien",
              fontWeight: "500",
              wordWrap: "break-word",
              visibility: !props.data ? "visible" : "hidden",
            }}
          >
            {props.mainheading}
          </span>
        </div>
        <div
          style={{
            left: 0,
            top: 155,
            position: "absolute",
            color: "black",
            fontSize: 24,
            fontFamily: "OdBestFreind",
            fontWeight: "400",
            wordWrap: "pre-wrap",
            visibility: !props.data ? "visible" : "hidden",
          }}
        >
          {props.description}
        </div>
      </div>
      <div
        style={{
          width: 600,
          height: 32,
          left: 1020,
          top: 162,
          position: "absolute",
          transform: "rotate(90deg)",
          transformOrigin: "0 0",
          color: "black",
          fontSize: 24,
          fontFamily: "Pretendard",
          fontWeight: "100",
          letterSpacing: 9.84,
          wordWrap: "break-word",
        }}
      >
        photodaehak originals
      </div>
    </div>
  );
}

export default KioskContentsHeader;
