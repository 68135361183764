import { useSearchParams } from "react-router-dom";
import React from "react";
import itemInfo from "../../assets/data/itemInfo.json";
import paymentForm from "../../components/paymentModule";
import { useNavigate } from "react-router-dom";
import { useTransition, animated } from "react-spring";
import image1 from "../../assets/images/homeimages/group1.jpeg";
import image2 from "../../assets/images/homeimages/group2.jpeg";
import image3 from "../../assets/images/homeimages/group3.jpeg";
import PayImage from "../../assets/images/payment_icon_yellow_small.png";

const Slideshow = ({ index, setIndex, descriptionImage }) => {
  React.useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % descriptionImage.length);
    }, 5000);
    return () => clearInterval(interval);
  });

  const transitions = useTransition(index, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 1000 },
    keys: index,
  });

  return (
    <div>
      {transitions((style, i, _, j) => (
        <animated.img
          key={j}
          src={process.env.PUBLIC_URL + descriptionImage[i]}
          style={{
            ...style,
            position: "absolute",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: 24,
            zIndex: -1,
          }}
        ></animated.img>
      ))}
    </div>
  );
};

function DescriptionPayView() {
  const [index, setIndex] = React.useState(0);

  const goPrev = () =>
    setIndex(
      (prev) => (descriptionImage.length + prev - 1) % descriptionImage.length
    );
  const goNext = () => setIndex((prev) => (prev + 1) % descriptionImage.length);

  let [searchParams] = useSearchParams();
  const itemName = searchParams.get("itemname");
  const descriptionImage = itemInfo[itemName]["descriptionImageList"];

  const contentsName = itemInfo[itemName]["name"];
  const contentsFee = itemInfo[itemName]["fee"];
  const contentsId = itemInfo[itemName]["prod_id"];
  const link = itemInfo[itemName]["link"];

  const navigate = useNavigate();

  return (
    <div
      style={{
        width: 1080,
        height: 1920,
        position: "relative",
        background: "rgba(255, 244, 79, 0.50)",
        zIndex: 0,
      }}
    >
      <div
        style={{
          width: 500,
          height: 66,
          left: 195,
          top: 155,
          position: "absolute",
          color: "black",
          fontSize: 50,
          fontFamily: "THEAlien",
          fontWeight: "400",
          wordWrap: "break-word",
        }}
      >
        {contentsName}
      </div>
      <div
        style={{
          left: 785,
          top: 179,
          textAlign: "right",
          position: "absolute",
          color: "black",
          fontSize: 32,
          fontFamily: "Pretendard",
          fontWeight: "400",
          wordWrap: "break-word",
        }}
      >
        {contentsFee ? `${contentsFee}원` : "Free"}
      </div>
      <div
        style={{
          width: 689,
          height: 1358,
          left: 195,
          top: 256,
          position: "absolute",
          borderRadius: 24,
          color: "transparent",
          border: "3px black solid",
        }}
      >
        <Slideshow
          index={index}
          setIndex={setIndex}
          descriptionImage={descriptionImage}
        />
      </div>
      <div
        style={{
          width: 66,
          height: 66,
          left: 323,
          top: 1489,
          position: "absolute",
        }}
      >
        <div
          style={{
            width: 66,
            height: 66,
            position: "absolute",
            background: "white",
            borderRadius: 9999,
          }}
        />
        <div
          style={{
            width: 44.88,
            height: 50,
            position: "absolute",
            transformOrigin: "0 0",
          }}
        >
          <div
            style={{
              width: 33.66,
              height: 18.75,
              left: 5.61,
              top: 9,
              position: "absolute",
            }}
            onClick={() => goPrev()}
          >
            <svg
              width="50"
              height="45"
              viewBox="0 0 50 45"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M33.8845 36.6556L19.4751 23.5152C19.1591 23.2285 18.9818 22.8415 18.9818 22.4382C18.9818 22.0349 19.1591 21.6478 19.4751 21.3611L33.8814 8.22079C34.1972 7.93231 34.3742 7.54402 34.3742 7.13955C34.3742 6.73509 34.1972 6.3468 33.8814 6.05832C33.7271 5.91626 33.5426 5.80336 33.339 5.72627C33.1353 5.64919 32.9165 5.60947 32.6954 5.60947C32.4744 5.60947 32.2556 5.64919 32.0519 5.72627C31.8482 5.80336 31.6638 5.91626 31.5095 6.05832L17.1032 19.1959C16.1566 20.0612 15.6262 21.2255 15.6262 22.4382C15.6262 23.6509 16.1566 24.8152 17.1032 25.6805L31.5095 38.818C31.6638 38.9605 31.8485 39.0738 32.0524 39.1511C32.2564 39.2285 32.4756 39.2683 32.697 39.2683C32.9184 39.2683 33.1376 39.2285 33.3415 39.1511C33.5455 39.0738 33.7301 38.9605 33.8845 38.818C34.2003 38.5295 34.3773 38.1413 34.3773 37.7368C34.3773 37.3323 34.2003 36.944 33.8845 36.6556Z"
                fill="black"
              />
            </svg>
          </div>
        </div>
      </div>
      <div
        style={{
          width: 66,
          height: 66,
          left: 695,
          top: 1489,
          position: "absolute",
          transformOrigin: "0 0",
        }}
      >
        <div
          style={{
            width: 66,
            height: 66,
            left: 0,
            top: 0,
            position: "absolute",
            transformOrigin: "0 0",
            background: "white",
            borderRadius: 9999,
          }}
        />
        <div
          style={{
            width: 44.88,
            height: 50,
            position: "absolute",
            transformOrigin: "0 0",
          }}
        >
          <div
            style={{
              width: 33.66,
              height: 18.75,
              position: "absolute",
              left: 8.61,
              top: 9,
            }}
            onClick={() => goNext()}
          >
            <svg
              width="50"
              height="45"
              viewBox="0 0 50 45"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.1155 8.34445L30.5249 21.4848C30.8409 21.7715 31.0182 22.1585 31.0182 22.5618C31.0182 22.9651 30.8409 23.3522 30.5249 23.6389L16.1186 36.7792C15.8028 37.0677 15.6258 37.456 15.6258 37.8605C15.6258 38.2649 15.8028 38.6532 16.1186 38.9417C16.2729 39.0837 16.4574 39.1966 16.661 39.2737C16.8647 39.3508 17.0835 39.3905 17.3046 39.3905C17.5256 39.3905 17.7444 39.3508 17.9481 39.2737C18.1518 39.1966 18.3362 39.0837 18.4905 38.9417L32.8968 25.8041C33.8434 24.9388 34.3738 23.7745 34.3738 22.5618C34.3738 21.3491 33.8434 20.1848 32.8968 19.3195L18.4905 6.18197C18.3362 6.03948 18.1515 5.92622 17.9476 5.84888C17.7436 5.77154 17.5244 5.73169 17.303 5.73169C17.0816 5.73169 16.8624 5.77154 16.6584 5.84888C16.4545 5.92622 16.2699 6.03948 16.1155 6.18197C15.7996 6.47046 15.6227 6.85874 15.6227 7.26321C15.6227 7.66768 15.7996 8.05596 16.1155 8.34445Z"
                fill="black"
              />
            </svg>
          </div>
        </div>
      </div>
      <div
        style={{
          width: 321,
          height: 153,
          left: 195,
          top: 1661,
          position: "absolute",
          background: "white",
          borderRadius: 24,
          border: "3px black solid",
        }}
        onClick={() => navigate("/")}
      >
        <div
          style={{
            width: "100%",
            height: 40,
            top: 56.5,
            position: "absolute",
            textAlign: "center",
            color: "black",
            fontSize: 36,
            fontFamily: "THEAlien",
            fontWeight: "400",
            wordWrap: "break-word",
          }}
        >
          홈 화면으로
        </div>
      </div>
      <div
        style={{
          width: 321,
          height: 153,
          left: 563,
          top: 1661,
          position: "absolute",
          background: "white",
          borderRadius: 24,
          border: "3px black solid",
        }}
        onClick={() =>
          paymentForm({
            fee: contentsFee,
            prod_name: contentsName,
            prod_id: contentsId,
            link: link,
            navigation: navigate,
          })
        }
      >
        <div
          style={{
            width: "100%",
            top: 56.5,
            height: 40,
            position: "absolute",
            textAlign: "center",
            color: "black",
            fontSize: 36,
            fontFamily: "THEAlien",
            fontWeight: "400",
            wordWrap: "break-word",
          }}
        >
          시작하기
        </div>
        <img
          style={{
            width: 321,
            height: 30,
            position: "relative",
            top: "80%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            objectFit: "scale-down",
          }}
          src={PayImage}
        />
      </div>
    </div>
  );
}

export default DescriptionPayView;
