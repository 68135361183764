import React, { useEffect, useState } from "react";
import { DataFetcher, downloadImage } from "./model";
import YuniImageLogo from "../../../../assets/images/logo-mascot-symbol.png";
import YuniImage from "../../../../assets/images/logo-mascot-only.png";
import QRCode from "qrcode.react";
import KioskContentsHeader from "../../../../components/KioskContentsHeader";
import VerifiedGIF from "../../../../assets/gifs/verified.gif";
import { useLocation } from "react-router-dom";
import CircleTimer from "../../../../components/rollbackTimer";
import FinalHomeButton from "../../../../components/finalHomeButton";
import { useNavigate } from "react-router-dom";

const AnimatedContextLoading = (props) => {
  const texts = [
    "윤이가 정성스레 재학증명서를 써주고 있습니다.",
    "윤이가 정성스레 재학증명서를 써주고 있습니다..",
    "윤이가 정성스레 재학증명서를 써주고 있습니다...",
    "윤이가 정성스레 재학증명서를 써주고 있습니다.",
    "윤이가 도장을 훔쳐 도장을 찍고 있습니다.",
    "윤이가 도장을 훔쳐 도장을 찍고 있습니다..",
    "윤이가 도장을 훔쳐 도장을 찍고 있습니다...",
    "윤이가 재학증명서를 먹고 있습니다.",
    "윤이가 재학증명서를 먹고 있습니다..",
    "윤이가 재학증명서를 먹고 있습니다...",
    "윤이가 재학증명서를 먹고 있습니다.",
    "윤이가 재학증명서를 먹고 있습니다..",
    "윤이가 재학증명서를 다시 써주고 있습니다.",
    "윤이가 재학증명서를 다시 써주고 있습니다..",
    "윤이가 재학증명서를 다시 써주고 있습니다...",
    "윤이가 인쇄 중입니다.",
    "윤이가 인쇄 중입니다..",
    "윤이가 인쇄 중입니다...",
    <img
      style={{
        width: 400,
        height: 400,
        position: "absolute",
        left: 340,
        top: 600,
      }}
      src={VerifiedGIF}
    />,
    <div
      style={{
        width: 816,
        height: 120,
        position: "absolute",
        color: "#000000",
        textAlign: "center",
        fontFamily: "Pretendard",
        fontWeight: 500,
        fontSize: 40,
        top: 780,
        left: 132,
        whiteSpace: "pre-wrap",
      }}
    >
      {"인쇄가 완료되었습니다.\n잠시 후 홈페이지로 돌아갑니다."}
    </div>,
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [printComplete, setPrintComplete] = useState(false);

  useEffect(() => {
    let duration = currentIndex < 17 ? 800 : 2000;
    let timer;

    if (props.data && !printComplete) {
      timer = setInterval(() => {
        setCurrentIndex((currentIndex) => {
          // 모든 텍스트를 순환했을 때, 인쇄 완료 상태로 설정
          if (currentIndex === texts.length - 1) {
            setPrintComplete(true);
            clearInterval(timer); // 타이머 정리
            return currentIndex;
          } else {
            return currentIndex + 1;
          }
        });
      }, duration);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [currentIndex, props.data, printComplete, texts.length]);

  if (currentIndex <= 17) {
    return (
      <div
        style={{
          position: "absolute",
          width: 816,
          height: 60,
          color: "#000000",
          textAlign: "center",
          fontFamily: "Pretendard",
          fontWeight: 100,
          fontSize: 40,
          top: 833,
          left: 132,
          visibility: props.data && !printComplete ? "visible" : "hidden",
        }}
      >
        {texts[currentIndex]}
      </div>
    );
  } else {
    return texts[currentIndex];
  }
};

function UnistEnrollmentPageView() {
  const [timestamp, setTimestamp] = useState(null);
  const location = useLocation();

  useEffect(() => {
    updateDataBasedOnLocation(location.pathname);
  }, [location]);

  const updateDataBasedOnLocation = (path) => {
    const now = new Date();
    setTimestamp(now.getTime());
  };

  let { data, loading } = DataFetcher({ auth: timestamp });
  console.log(data, timestamp);
  useEffect(() => {
    if (data) {
      downloadImage({ data }).then((success) => {
        if (success) {
          console.log("다운로드 성공");
          // 필요한 경우 여기에서 추가 작업 수행
        } else {
          console.error("다운로드 실패");
        }
      });
    }
  }, [data]);

  const qrCodeUrl = `https://photodaehak.shop/mobile/unist-enrollment?auth=${timestamp}`;

  const navigate = useNavigate();

  return (
    <div style={{ position: "absolute", top: 0, left: 0 }}>
      <FinalHomeButton isDone={data} navigation={navigate} />
      <CircleTimer duration={300} isResult={data} />
      <AnimatedContextLoading data={data} />
      <KioskContentsHeader
        subheading={"윤이가 말아주는 ★"}
        mainheading={"유니스트 재학증명서"}
        description={
          "재학증명서를 떼달라고 했는데 이걸 보여줄 경우\n바보 취급을 당할 수 있음에 주의하세요"
        }
        color={"rgba(255, 244, 79, 0.50)"}
      />
      <div
        style={{
          width: 436,
          height: 436,
          left: 321,
          top: 646,
          position: "absolute",
          visibility: !data ? "visible" : "hidden",
        }}
      >
        <QRCode value={qrCodeUrl} size={436} bgColor="transparent" />
      </div>
      <div
        style={{
          left: 120,
          width: 1000,
          top: 1615,
          position: "absolute",
          color: "black",
          fontSize: 36,
          fontFamily: "THEAlien",
          fontWeight: "400",
          wordWrap: "break-word",
          visibility: !data ? "visible" : "hidden",
        }}
      >
        위 QR코드에 접속해 성명과 학번, 입학연도를 입력해주세요
      </div>
      <img
        style={{
          width: 188,
          height: 314,
          left: 719,
          top: 126,
          position: "absolute",
        }}
        src={YuniImage}
      />
      <img
        style={{
          width: 574,
          height: 171,
          left: 253,
          top: 1207,
          position: "absolute",
        }}
        src={YuniImageLogo}
      />
    </div>
  );
}

export default UnistEnrollmentPageView;
