import React from "react";
import { Bootpay } from "@bootpay/client-js";

async function paymentForm({ fee, prod_name, prod_id, link, navigation }) {
  const response = await Bootpay.requestPayment({
    application_id: "64c01b6000be04001c77a63a",
    price: fee,
    order_name: "사진대학 콘텐츠 - " + prod_name,
    order_id: prod_id,
    pg: "카카오페이",
    method: "간편",
    items: [
      {
        id: prod_id,
        name: "사진대학 콘텐츠 - " + prod_name,
        qty: 1,
        price: fee,
      },
    ],
    extra: {
      open_type: "iframe",
    },
  });
  try {
    switch (response.event) {
      case "ready":
        console.log("결제 준비 완료");
        break;
      case "close":
        console.log("결제 창 닫힘");
        break;
      case "confirm":
        console.log("결제 확인");
        break;
      case "cancel":
        console.log("결제 취소");
        break;
      case "done":
        console.log("결제 완료");
        navigation(link);
        break;
    }
  } catch (e) {
    console.log(e.message);
    switch (e.event) {
      case "cancel":
        console.log(e.message);
        break;
      case "error":
        console.log(e.error_code);
        break;
    }
  }
}

export default paymentForm;
