import styled from "styled-components";
import React from "react";
import "../assets/fonts/fonts.css";

const LayerBlur = styled.div`
  position: absolute;
  width: 1080px;
  height: 1920px;
  background: linear-gradient(
    180deg,
    rgba(53, 53, 53, 0.9) 50%,
    rgba(0, 0, 0, 0) 90%,
    rgba(0, 0, 0, 0) 130%
  );
  backdrop-filter: blur(35px);
`;

const GridContainer = styled.div`
  position: absolute;
  background: linear-gradient(
    150.04deg,
    rgba(13, 13, 13, 0.42) 73.25%,
    rgba(171, 171, 171, 0.56) 302.06%
  );
  border-radius: 36px;
  padding: 40px;
  font-family: Pretendard;
  box-sizing: border-box;
`;

export { GridContainer, LayerBlur };
