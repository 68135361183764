import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const CircleTimer = ({ duration = 180, isResult }) => {
  const [timeLeft, setTimeLeft] = useState(duration);
  const navigate = useNavigate();
  const timerId = useRef(null);

  // 원의 둘레를 계산합니다.
  const radius = 50; // 반지름
  const circumference = 2 * Math.PI * radius;

  const startTimer = () => {
    if (timerId.current) {
      clearInterval(timerId.current); // 기존 타이머를 정리합니다.
    }
    timerId.current = setInterval(() => {
      setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);
  };

  // 사용자 활동을 감지하고 타이머를 리셋하는 함수
  const resetTimer = () => {
    setTimeLeft(duration);
    startTimer();
  };

  const resetTimerWithResult = () => {
    duration = 60;
    setTimeLeft(duration);
    startTimer();
  };

  useEffect(() => {
    // 사용자 활동 감지를 위한 이벤트 리스너 추가
    window.addEventListener("click", resetTimer);
    if (isResult) {
      resetTimerWithResult();
    } else {
      // 타이머 시작
      startTimer();
    }
    return () => {
      // 이벤트 리스너와 타이머 정리
      window.removeEventListener("click", resetTimer);
      clearInterval(timerId.current);
    };
  }, [duration, navigate, isResult]);

  // 타이머가 끝났을 때 홈페이지로 이동
  useEffect(() => {
    if (timeLeft === 0) {
      navigate("/");
    }
  }, [timeLeft, navigate]);

  // 남은 시간에 따라 원의 strokeDashoffset을 계산합니다.
  const strokeDashoffset =
    circumference - (timeLeft / duration) * circumference;

  return (
    <div style={{ position: "absolute", zIndex: 1 }}>
      <svg width="120" height="120" viewBox="0 0 120 120">
        <circle
          cx="60"
          cy="60"
          r={radius}
          fill="none"
          stroke="#eee"
          strokeWidth="10"
        />
        <circle
          cx="60"
          cy="60"
          r={radius}
          fill="none"
          stroke="tomato"
          strokeWidth="10"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          transform="rotate(-90 60 60)"
        />
      </svg>
      <div
        style={{
          textAlign: "center",
          top: "-50%",
          transform: "translateY(-390%)",
          fontFamily: "Pretendard",
          fontWeight: 500,
        }}
      >
        {timeLeft}s
      </div>
    </div>
  );
};

export default CircleTimer;
