import React from "react";

const FinalHomeButton = ({ isDone, navigation }) => {
  if (isDone) {
    return (
      <div
        style={{
          width: 700,
          height: 153,
          position: "relative",
          top: 1681,
          left: 195,
          zIndex: 1,
        }}
        onClick={() => navigation("/")}
      >
        <div
          style={{
            width: "100%",
            height: 153,
            left: 0,
            top: 0,
            position: "absolute",
            background: "white",
            borderRadius: 24,
            border: "3px black solid",
          }}
        />
        <div
          style={{
            width: "100%",
            height: 40,
            left: 14,
            top: 57,
            position: "absolute",
            textAlign: "center",
            color: "black",
            fontSize: 36,
            fontFamily: "THEAlien",
            fontWeight: "400",
            wordWrap: "break-word",
          }}
        >
          홈 화면으로
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default FinalHomeButton;
