import React, { useEffect, useState, useRef } from "react";
import ContentsHeaderBox from "../../../../components/SubmissionContentsHeader";
import { usePhysForm, showImage, confirmImage } from "./model";
import { useSearchParams } from "react-router-dom";
import SubmitStatusShowBox from "../../../../components/SubmitStautsShower";
import YuniImageLogo from "../../../../assets/images/logo-mascot-symbol.png";
import YuniImage from "../../../../assets/images/logo-mascot-only.png";

function ImageConfirm({
  imageURL,
  faceNum,
  setSubmitted,
  setCompleted,
  setFailed,
  setImageURL,
  setGetImage,
  setFaceNum,
  confirmRequested,
  confirmed,
  confirmFailed,
  handleConfirm,
}) {
  const resetSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(false);
    setCompleted(false);
    setFailed(false);
    setImageURL("");
    setGetImage(false);
    setFaceNum(0);
  };

  let available = false;

  if (faceNum < 2) {
    available = false;
  } else {
    available = true;
  }

  console.log("얼굴 감지 개수:", faceNum, available);

  return (
    <div
      style={{
        width: "80%",
        height: "50%",
        visibility:
          imageURL && !confirmRequested && !confirmed && !confirmFailed
            ? "visible"
            : "hidden",
        left: "50%",
        top: "50%",
        position: "absolute",
      }}
    >
      <img
        src={imageURL}
        style={{
          width: "100%",
          height: "90%",
          transform: "translate(-50%, -50%)",
          objectFit: "scale-down",
        }}
      ></img>
      <div
        style={{
          marginTop: 10,
          width: "100%",
          transform: "translate(-50%, -150%)",
          fontFamily: "Pretendard",
          fontWeight: 400,
          fontSize: 16,
          textAlign: "center",
        }}
      >
        {available
          ? "얼굴인식이 완료되었습니다. 위 사진으로 진행하시겠습니까?"
          : "인식된 얼굴이 2개보다 작습니다. 사진을 다시 업로드해 주세요."}
      </div>
      <div>
        <button
          style={{
            width: "40%",
            height: 30,
            transform: "translate(-120%, -100%)",
            fontFamily: "Pretendard",
            fontWeight: 800,
            fontSize: 14,
            textAlign: "center",
            borderRadius: 8,
            backgroundColor: "rgba(255, 255, 255, 0.19)",
            boxShadow: "inset 0px 0px 8px 2px rgba(0, 0, 0, 0.20)",
            visibility:
              imageURL &&
              available &&
              !confirmRequested &&
              !confirmed &&
              !confirmFailed
                ? "visible"
                : "hidden",
          }}
          onClick={(e) => {
            handleConfirm(e);
          }}
        >
          예
        </button>
        <button
          style={{
            width: "40%",
            height: 30,
            transform: "translate(-80%, -100%)",
            fontFamily: "Pretendard",
            fontWeight: 800,
            fontSize: 14,
            textAlign: "center",
            borderRadius: 8,
            backgroundColor: "rgba(255, 255, 255, 0.19)",
            boxShadow: "inset 0px 0px 8px 2px rgba(0, 0, 0, 0.20)",
            visibility:
              imageURL &&
              available &&
              !confirmRequested &&
              !confirmed &&
              !confirmFailed
                ? "visible"
                : "hidden",
          }}
          onClick={(e) => resetSubmit(e)}
        >
          아니오
        </button>
        <button
          style={{
            width: "60%",
            height: 30,
            transform: "translate(-50%, -100%)",
            fontFamily: "Pretendard",
            fontWeight: 800,
            fontSize: 14,
            textAlign: "center",
            borderRadius: 8,
            backgroundColor: "rgba(255, 255, 255, 0.19)",
            boxShadow: "inset 0px 0px 8px 2px rgba(0, 0, 0, 0.20)",
            visibility: imageURL && !available ? "visible" : "hidden",
          }}
          onClick={(e) => resetSubmit(e)}
        >
          다시 업로드
        </button>
      </div>
    </div>
  );
}

function ImageUpload({
  capture,
  icon,
  style,
  text,
  file,
  setFile,
  handleSubmit,
}) {
  const fileInputRef = useRef();

  const handleImageUpload = (event) => {
    handleSubmit(event);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      <input
        style={{
          display: "none",
        }}
        type="file"
        ref={fileInputRef}
        accept="image/*"
        capture={capture}
        onChange={handleImageUpload}
      />
      <div style={style}>
        <div
          style={{
            width: 85,
            height: 85,
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            background: "white",
            borderRadius: 9999,
          }}
          onClick={handleClick}
        >
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {icon}
          </div>
        </div>
        <div
          style={{
            width: 130,
            height: 17,
            fontFamily: "Pretendard",
            fontWeight: 200,
            fontSize: 14,
            color: "#222222",
            position: "absolute",
            left: "50%",
            top: 60,
            textAlign: "center",
            transform: "translate(-50%, 0%)",
          }}
        >
          {text}
        </div>
      </div>
    </div>
  );
}

function PhysiognomyMatchMobileView() {
  let {
    file,
    setFile,
    auth,
    setAuth,
    faceNum,
    setFaceNum,
    imageURL,
    setImageURL,
    submitted,
    setSubmitted,
    completed,
    setCompleted,
    failed,
    setFailed,
    getImage,
    setGetImage,
    handleSubmit,
  } = usePhysForm();

  let {
    confirmRequested,
    setConfirmRequested,
    confirmed,
    setConfirmed,
    confirmFailed,
    setConfirmFailed,
    auth2,
    setAuth2,
    handleConfirm,
  } = confirmImage();

  let [searchParams] = useSearchParams();
  const authcode = searchParams.get("auth");

  useEffect(() => {
    setAuth(authcode);
    setAuth2(authcode);
  }, []);

  return (
    <div
      style={{
        visibility: auth ? "visible" : "hidden",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <ContentsHeaderBox
        subheading={"관상으로 알아보는"}
        mainheading={"환상&환장의 궁합"}
        instruction={"아래에서 사진을 업로드해 주세요."}
        color={"rgba(244, 175, 175, 0.90)"}
      />
      <ImageUpload
        capture="environment"
        icon={
          <svg
            width="45"
            height="45"
            viewBox="0 0 45 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M28.125 5.625C29.0711 5.6247 29.9823 5.98201 30.676 6.62531C31.3697 7.2686 31.7947 8.15033 31.8656 9.09375L31.875 9.375C31.8751 9.83425 32.0437 10.2775 32.3488 10.6207C32.654 10.9639 33.0745 11.1831 33.5306 11.2369L33.75 11.25H35.625C37.0598 11.2499 38.4403 11.7981 39.4842 12.7824C40.5281 13.7667 41.1565 15.1127 41.2406 16.545L41.25 16.875V33.75C41.2501 35.1848 40.7019 36.5653 39.7176 37.6092C38.7333 38.6531 37.3873 39.2815 35.955 39.3656L35.625 39.375H9.375C7.94023 39.3751 6.55966 38.8269 5.51576 37.8426C4.47186 36.8583 3.84355 35.5123 3.75938 34.08L3.75 33.75V16.875C3.74992 15.4402 4.29811 14.0597 5.28241 13.0158C6.2667 11.9719 7.6127 11.3435 9.045 11.2594L9.375 11.25H11.25C11.7473 11.25 12.2242 11.0525 12.5758 10.7008C12.9275 10.3492 13.125 9.87228 13.125 9.375C13.1247 8.42892 13.482 7.51769 14.1253 6.82397C14.7686 6.13026 15.6503 5.70533 16.5938 5.63438L16.875 5.625H28.125ZM22.5 18.75C21.1061 18.7499 19.7618 19.2673 18.7277 20.202C17.6937 21.1366 17.0434 22.4219 16.9031 23.8088L16.8825 24.0938L16.875 24.375L16.8825 24.6562C16.9375 25.7554 17.3138 26.8143 17.9647 27.7018C18.6156 28.5892 19.5125 29.2662 20.5444 29.6489C21.5763 30.0317 22.6978 30.1033 23.7699 29.8549C24.8421 29.6065 25.8178 29.0491 26.5763 28.2516C27.3348 27.4541 27.8427 26.4517 28.037 25.3684C28.2314 24.2852 28.1037 23.1687 27.6698 22.1573C27.2359 21.1459 26.5148 20.2839 25.5959 19.6783C24.677 19.0727 23.6006 18.7499 22.5 18.75Z"
              fill="black"
            />
          </svg>
        }
        style={{
          position: "absolute",
          left: "50%",
          top: "40%",
          transform: "translate(-50%, -50%)",
          visibility: failed || submitted || completed ? "hidden" : "visible",
        }}
        text={"새로운 사진 찍기"}
        file={file}
        setFile={setFile}
        handleSubmit={handleSubmit}
      />
      <ImageUpload
        icon={
          <svg
            width="41"
            height="41"
            viewBox="0 0 41 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.36364 31.6364V38H38V10.9068H31.6364M4.59091 30.0455L12.5455 20.5L17.3182 25.2727L23.6818 17.3182L31.6364 26.8636M3 3H31.6364V31.6364H3V3ZM10.9545 14.1364C11.3765 14.1364 11.7811 13.9688 12.0795 13.6704C12.3778 13.372 12.5455 12.9674 12.5455 12.5455C12.5455 12.1235 12.3778 11.7189 12.0795 11.4205C11.7811 11.1222 11.3765 10.9545 10.9545 10.9545C10.5326 10.9545 10.128 11.1222 9.8296 11.4205C9.53125 11.7189 9.36364 12.1235 9.36364 12.5455C9.36364 12.9674 9.53125 13.372 9.8296 13.6704C10.128 13.9688 10.5326 14.1364 10.9545 14.1364Z"
              stroke="black"
              strokeWidth="5"
            />
          </svg>
        }
        style={{
          position: "absolute",
          left: "50%",
          top: "80%",
          transform: "translate(-50%, -50%)",
          visibility:
            failed || submitted === true || completed ? "hidden" : "visible",
        }}
        text={"앨범에서 업로드하기"}
        file={file}
        setFile={setFile}
        handleSubmit={handleSubmit}
      />
      <SubmitStatusShowBox
        submitted={submitted}
        completed={false}
        failed={failed}
        turnoff={getImage}
      />
      <SubmitStatusShowBox
        submitted={confirmRequested}
        completed={confirmed}
        failed={confirmFailed}
        turnoff={false}
      />
      <ImageConfirm
        handleConfirm={handleConfirm}
        confirmed={confirmed}
        confirmFailed={confirmFailed}
        faceNum={faceNum}
        imageURL={imageURL}
        setSubmitted={setSubmitted}
        setCompleted={setCompleted}
        setFailed={setFailed}
        setImageURL={setImageURL}
        setGetImage={setGetImage}
        setFaceNum={setFaceNum}
        confirmRequested={confirmRequested}
      />
    </div>
  );
}

export default PhysiognomyMatchMobileView;
