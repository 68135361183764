import React, { useEffect, useState } from "react";
import { DataFetcher, downloadImage } from "./model";
import QRCode from "qrcode.react";
import KioskContentsHeader from "../../../../components/KioskContentsHeader";
import { useLocation } from "react-router-dom";
import HeartImage from "../../../../assets/images/heart+broken_heart.png";
import CircleTimer from "../../../../components/rollbackTimer";
import { useNavigate } from "react-router-dom";
import FinalHomeButton from "../../../../components/finalHomeButton";

function HeartSign({ isBroken }) {
  if (!isBroken) {
    return (
      <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M30 53.375L26.375 50.075C13.5 38.4 5 30.675 5 21.25C5 13.525 11.05 7.5 18.75 7.5C23.1 7.5 27.275 9.525 30 12.7C32.725 9.525 36.9 7.5 41.25 7.5C48.95 7.5 55 13.525 55 21.25C55 30.675 46.5 38.4 33.625 50.075L30 53.375Z"
          fill="url(#paint0_linear_38_637)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_38_637"
            x1="20"
            y1="7"
            x2="45"
            y2="53"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.09" stopColor="#FF034F" />
            <stop offset="1" stopColor="#B900BD" stopOpacity="0.4" />
          </linearGradient>
        </defs>
      </svg>
    );
  } else {
    return (
      <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M30.0625 54.8125C24.4792 49.1875 20.0317 44.6458 16.72 41.1875C13.4083 37.7292 10.8875 34.8542 9.1575 32.5625C7.4275 30.2708 6.3025 28.3125 5.7825 26.6875C5.2625 25.0625 5.00167 23.25 5 21.25C5 17.4167 6.33333 14.1667 9 11.5C11.6667 8.83333 14.9167 7.5 18.75 7.5C20.625 7.5 22.4375 7.84333 24.1875 8.53C25.9375 9.21667 27.5 10.2067 28.875 11.5L25 25H32.5L30.375 45.9375L37.5 22.5H30L34.4375 9.25C35.4792 8.66667 36.5733 8.22917 37.72 7.9375C38.8667 7.64583 40.0433 7.5 41.25 7.5C45.0833 7.5 48.3333 8.83333 51 11.5C53.6667 14.1667 55 17.4167 55 21.25C55 23.25 54.7292 25.0833 54.1875 26.75C53.6458 28.4167 52.5 30.4067 50.75 32.72C49 35.0317 46.4792 37.9067 43.1875 41.345C39.8958 44.7833 35.5208 49.2725 30.0625 54.8125Z"
          fill="url(#paint0_linear_38_639)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_38_639"
            x1="17"
            y1="18.5"
            x2="40.5"
            y2="40"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#9C0000" stop-opacity="0.8" />
            <stop offset="1" stop-color="#3E2323" />
          </linearGradient>
        </defs>
      </svg>
    );
  }
}

function FaceImage({ base64String, format }) {
  return (
    <img
      src={`data:image/${format};base64,${base64String}`}
      style={{ width: "100%", height: "100%", objectFit: "contain" }}
    />
  );
}

function ShowResults({ data }) {
  if (!data) return null;
  else if (data["result"]["result"].length < 2)
    return (
      <div
        style={{
          position: "relative",
          width: 700,
          height: 1200,
          left: 195,
          top: 256,
        }}
      >
        <div
          style={{
            width: 700,
            height: 1200,
            left: 0,
            top: 0,
            position: "absolute",
            background:
              "linear-gradient(180deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.10) 100%)",
            borderRadius: 24,
            border: "3px black solid",
          }}
        />
        <div
          style={{
            width: 448,
            left: 126,
            top: 505,
            position: "absolute",
            color: "black",
            fontSize: 20,
            fontFamily: "Pretendard",
            fontWeight: "300",
            wordWrap: "pre-wrap",
          }}
        >
          {data["result"]["result"][0]["message"]}
        </div>
        <div
          style={{
            left: 51,
            top: 157,
            position: "absolute",
            color:
              data["result"]["result"][0]["name"] === "환장의 궁합"
                ? "#9C0000"
                : "#FF0D90",
            fontSize: 48,
            fontFamily: "Pretendard",
            fontWeight: "600",
            wordWrap: "break-word",
          }}
        >
          {data["result"]["result"][0]["name"]}
        </div>
        <div
          style={{
            width: 60,
            height: 60,
            left: 320,
            top: 346,
            position: "absolute",
          }}
        >
          <HeartSign
            isBroken={
              data["result"]["result"][0]["name"] === "환장의 궁합"
                ? true
                : false
            }
          />
        </div>
        <div
          style={{
            width: 158,
            height: 158,
            left: 87,
            top: 297,
            position: "absolute",
          }}
        >
          <FaceImage
            base64String={data["result"]["result"][0]["face"][0]}
            format={data["fileFormat"]}
          />
        </div>
        <div
          style={{
            width: 158,
            height: 158,
            left: 455,
            top: 297,
            position: "absolute",
          }}
        >
          <FaceImage
            base64String={data["result"]["result"][0]["face"][1]}
            format={data["fileFormat"]}
          />
        </div>
      </div>
    );
  return (
    <div
      style={{
        position: "relative",
        width: 700,
        height: 1200,
        left: 195,
        top: 256,
      }}
    >
      <div
        style={{
          width: 700,
          height: 1200,
          left: 0,
          top: 0,
          position: "absolute",
          background:
            "linear-gradient(180deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.10) 100%)",
          borderRadius: 24,
          border: "3px black solid",
        }}
      />
      <div
        style={{
          width: 448,
          left: 126,
          top: 505,
          position: "absolute",
          color: "black",
          fontSize: 20,
          fontFamily: "Pretendard",
          fontWeight: "300",
          wordWrap: "pre-wrap",
        }}
      >
        {data["result"]["result"][0]["message"]}
      </div>
      <div
        style={{
          width: 448,
          left: 121,
          top: 1075,
          position: "absolute",
          color: "black",
          fontSize: 20,
          fontFamily: "Pretendard",
          fontWeight: "300",
          wordWrap: "pre-wrap",
        }}
      >
        {data["result"]["result"][1]["message"]}
      </div>
      <div
        style={{
          left: 51,
          top: 157,
          position: "absolute",
          color: "#FF0D90",
          fontSize: 48,
          fontFamily: "Pretendard",
          fontWeight: "600",
          wordWrap: "break-word",
        }}
      >
        {data["result"]["result"][0]["name"]}
      </div>
      <div
        style={{
          left: 51,
          top: 744,
          position: "absolute",
          color: "#9C0000",
          fontSize: 48,
          fontFamily: "Pretendard",
          fontWeight: "600",
          wordWrap: "break-word",
        }}
      >
        {data["result"]["result"][1]["name"]}
      </div>
      <div
        style={{
          width: 60,
          height: 60,
          left: 320,
          top: 346,
          position: "absolute",
        }}
      >
        <HeartSign isBroken={false} />
      </div>
      <div
        style={{
          width: 60,
          height: 60,
          left: 320,
          top: 916,
          position: "absolute",
        }}
      >
        <HeartSign isBroken={true} />
      </div>
      <div
        style={{
          width: 158,
          height: 158,
          left: 87,
          top: 297,
          position: "absolute",
        }}
      >
        <FaceImage
          base64String={data["result"]["result"][0]["face"][0]}
          format={data["fileFormat"]}
        />
      </div>
      <div
        style={{
          width: 158,
          height: 158,
          left: 455,
          top: 297,
          position: "absolute",
        }}
      >
        <FaceImage
          base64String={data["result"]["result"][0]["face"][1]}
          format={data["fileFormat"]}
        />
      </div>

      <div
        style={{
          width: 158,
          height: 158,
          left: 87,
          top: 867,
          position: "absolute",
        }}
      >
        <FaceImage
          base64String={data["result"]["result"][1]["face"][0]}
          format={data["fileFormat"]}
        />
      </div>
      <div
        style={{
          width: 158,
          height: 158,
          left: 455,
          top: 867,
          position: "absolute",
        }}
      >
        <FaceImage
          base64String={data["result"]["result"][1]["face"][1]}
          format={data["fileFormat"]}
        />
      </div>
    </div>
  );
}

function PhysiognomyMatchView() {
  const [timestamp, setTimestamp] = useState(null);
  const [isResult, setIsResult] = useState(false);
  const location = useLocation();

  useEffect(() => {
    updateDataBasedOnLocation(location.pathname);
  }, [location]);

  const updateDataBasedOnLocation = (path) => {
    const now = new Date();
    setTimestamp(now.getTime());
  };

  let { data, loading } = DataFetcher({ auth: timestamp });
  console.log(data, timestamp);

  useEffect(() => {
    if (data) {
      const link = data["result"]["resultpath"];
      console.log(link);
      if (link) {
        downloadImage(link).then((success) => {
          if (success) {
            console.log("다운로드 성공");
            // 필요한 경우 여기에서 추가 작업 수행
          } else {
            console.error("다운로드 실패");
          }
        });
      }
    }
  }, [data]);

  const qrCodeUrl = `https://photodaehak.shop/mobile/physiognomy-match?auth=${timestamp}`;

  return (
    <div style={{ position: "absolute", top: 0, left: 0 }}>
      <FinalHomeButton isDone={data} navigation={useNavigate()} />
      <CircleTimer duration={300} isResult={data} />
      <KioskContentsHeader
        subheading={"관상으로 알아보는"}
        mainheading={"환상&환장의 궁합"}
        description={"당신의 얼굴로 알아보는 환상&환장의 궁합을 확인해보세요!"}
        color={"rgba(244, 175, 175, 0.90)"}
        data={data}
      />
      <ShowResults data={data} />
      <div
        style={{
          width: 436,
          height: 436,
          left: 321,
          top: 646,
          position: "absolute",
          visibility: !data ? "visible" : "hidden",
        }}
      >
        <QRCode value={qrCodeUrl} size={436} bgColor="transparent" />
      </div>
      <div
        style={{
          left: 120,
          width: 820,
          top: 1415,
          position: "absolute",
          textAlign: "center",
          color: "black",
          fontSize: 36,
          fontFamily: "THEAlien",
          fontWeight: "400",
          wordWrap: "break-word",
          visibility: !data ? "visible" : "hidden",
          whiteSpace: "pre-wrap",
        }}
      >
        {
          "위 QR코드에 접속해 함께 찍은 사진을 업로드해주세요!\n자동으로 얼굴을 감지해 궁합을 찾아드립니다!"
        }
      </div>
      <img
        style={{
          width: 246,
          height: 222,
          left: 679,
          top: 156,
          position: "absolute",
        }}
        src={HeartImage}
      />
    </div>
  );
}

export default PhysiognomyMatchView;
